import React, { useEffect, useState } from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from 'src/assets/user_menu_icons/report-close.svg'
import { ReactComponent as DownloadIcon } from 'src/assets/invoice_icons/download.svg'

import { CModal, CModalBody } from '@coreui/react'

import styles from './multiReportComponent.module.scss'
import SelectDropdown from '../SelectDropdown/SelectDropdown'
import moment from 'moment'
import { downloadInvoice } from 'src/views/Invoices/Invoices'

const ReportTypes = Object.freeze({
  MultiAccountInvoiceSummary: 'Multi-Account Invoice Summary',
  MultiAccountSnapshot: 'Multi-Account Snapshot',
})

const getYears = (startDate, currentDate) => {
  const years = new Set()
  const createdDate = startDate.clone()
  while (true) {
    const createdYear = createdDate.get('year')
    const currentYear = currentDate.get('year')
    if (createdYear > currentYear) {
      break
    }
    years.add(createdYear)
    createdDate.add(1, 'year')
  }
  return Array.from(years)
}

const getMonths = (startDate, endDate, selectedYear) => {
  const months = new Set()
  let createdDate = startDate.clone()
  let currentDate = endDate.clone()
  if (currentDate.format('YYYY') === selectedYear) {
    createdDate = currentDate.clone().startOf('year')
  } else if (createdDate.format('YYYY') === selectedYear) {
    currentDate = moment(selectedYear, 'YYYY').endOf('year')
  } else {
    createdDate = moment(selectedYear, 'YYYY').startOf('year')
    currentDate = moment(selectedYear, 'YYYY').endOf('year')
  }
  while (true) {
    if (createdDate.isAfter(currentDate)) {
      break
    }
    months.add(createdDate.format('MMMM'))
    createdDate.add(1, 'month')
  }
  return Array.from(months)
}

const getMonthsAndYears = (accountCreatedDate = '', selectedDates) => {
  const { year: selectedYear } = selectedDates
  if (!accountCreatedDate) {
    const date = moment()
    const year = date.format('YYYY')
    const month = date.format('MMMM')
    return [[month], [year]]
  }
  const createdDate = moment(accountCreatedDate)
  const currentDate = moment()

  const selectableYears = getYears(createdDate, currentDate)
  const selectableMonths = getMonths(createdDate, currentDate, selectedYear)
  return [selectableMonths, selectableYears]
}

const getAccountName = (contactList, currentAccount) => {
  if (!currentAccount && !contactList?.length) {
    return ''
  }
  const { SecondaryContact } = currentAccount
  const contact = contactList.find((contact) => contact.id === SecondaryContact)
  if (!contact) {
    return ''
  }
  return `${contact.firstname} ${contact.lastname}`
}

const MultiReportComponent = ({
  viewAccount,
  contactList,
  openMultiReport,
  setOpenMultiReport,
  setAlertComponent,
  setLoading,
}) => {
  const { date_joined = '', createdtime = '' } = viewAccount
  const currentDate = moment()

  const [form, setForm] = useState({
    month: currentDate.format('MMMM'),
    year: currentDate.format('YYYY'),
  })
  const [requested, setRequested] = useState({
    isRequested: false,
    requestedMonth: '',
    requestedYear: '',
  })
  const [reportType, setReportType] = useState('Multi-Account Invoice Summary')

  const [month, year] = getMonthsAndYears(date_joined || createdtime, form)

  const buildDates = () => {
    const start = moment(
      `${requested.requestedYear} ${requested.requestedMonth} 01`,
      'YYYY MMMM DD',
    )
    const end = moment(
      `${requested.requestedYear} ${requested.requestedMonth} 01`,
      'YYYY MMMM DD',
    ).endOf('month')
    return { start, end }
  }

  useEffect(() => {
    if (form.month === requested.requestedMonth && form.year === requested.requestedYear) {
      return
    }

    setRequested({
      isRequested: true,
      requestedMonth: form.month,
      requestedYear: form.year,
    })
  }, [form, reportType])

  const isPreBill = viewAccount?.fo_billing_period !== 'Only after delivery'
  const onClickDownload = downloadInvoice(
    setAlertComponent,
    setLoading,
    viewAccount?.fruitful_account_id,
  )

  const availableReport = () => {
    const { isRequested, requestedMonth, requestedYear } = requested
    if (!isRequested && !reportType) {
      return <></>
    }
    return (
      <div className={styles.generatedReportContainerDiv}>
        <div>Generated Report:</div>
        <div className={styles.reportContainerDiv}>
          <div>
            {reportType}{' '}
            {reportType !== ReportTypes.MultiAccountSnapshot && (
              <span className={styles.reportYearMonth}>
                {requestedMonth} {requestedYear}
              </span>
            )}
          </div>
          <div
            className={styles.downloadButton}
            onClick={() =>
              onClickDownload(
                '',
                '',
                isPreBill,
                false,
                true,
                reportType === ReportTypes.MultiAccountSnapshot,
                buildDates(),
              )
            }
          >
            <div>
              <DownloadIcon />
            </div>
            <div>Download Report</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <CModal alignment="center" visible={openMultiReport}>
      <CModalBody className={styles.modalStyle}>
        <div className={styles.headerContainerDiv}>
          <div className={styles.headerTitleDiv}>
            <div>Multi-Account Reports</div>
            <div className={styles.closeIconDiv} onClick={() => setOpenMultiReport(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.headerNameDiv}>{getAccountName(contactList, viewAccount)}</div>
        </div>
        <div className={styles.descriptionContainerDiv}>
          <div>
            Download the report with information on all the <b>accounts</b> you manage.
          </div>
        </div>
        <div className={styles.formContainerDiv}>
          <div className={styles.singleFieldContainer}>
            <SelectDropdown
              helperText={'Report Type'}
              helperTextStyle={styles.helperTextStyle}
              name={'reportType'}
              options={[ReportTypes.MultiAccountInvoiceSummary, ReportTypes.MultiAccountSnapshot]}
              value={reportType}
              formControlInput={styles.formControlInput}
              selectStyle={styles.selectStyle}
              handleChange={(e) => {
                e.preventDefault()
                setReportType(e.target.value)
              }}
              menuItemStyle={styles.menuItem}
              menuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    color: '#333333',
                    boxShadow: '0px 0px 8px #00000026',
                  },
                },
              }}
              additionalStyling={{
                '& legend': { display: 'none' },
                '& fieldset': {
                  borderRadius: '8px',
                  border: '1px solid #EAEAEA !important',
                  paddingBottom: '6px',
                },
              }}
            />
          </div>
          <div className={styles.descriptionContainerDiv} style={{ margin: '10px 0px' }}>
            Please pick the month and year of the report you want to generate:
          </div>
          <div className={styles.doubleFieldContainer}>
            <SelectDropdown
              isDisabled={reportType === ReportTypes.MultiAccountSnapshot}
              helperText={'Month'}
              helperTextStyle={styles.helperTextStyle}
              name={'month'}
              options={month}
              value={form.month}
              formControlInput={styles.formControlInput}
              selectStyle={styles.selectStyle}
              handleChange={(e) => {
                e.preventDefault()
                const { name, value } = e.target
                setForm((currentValues) => ({
                  ...currentValues,
                  [name]: value,
                }))
              }}
              menuItemStyle={styles.menuItem}
              menuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    color: '#333333',
                    boxShadow: '0px 0px 8px #00000026',
                  },
                },
              }}
              additionalStyling={{
                '& legend': { display: 'none' },
                '& fieldset': {
                  borderRadius: '8px',
                  border: '1px solid #EAEAEA !important',
                  paddingBottom: '6px',
                },
              }}
            />
            <SelectDropdown
              isDisabled={reportType === ReportTypes.MultiAccountSnapshot}
              helperText={'Year'}
              helperTextStyle={styles.helperTextStyle}
              name={'year'}
              options={year}
              value={form.year}
              formControlInput={styles.formControlInput}
              selectStyle={styles.selectStyle}
              handleChange={(e) => {
                e.preventDefault()
                const { name, value } = e.target
                setForm((currentValues) => ({
                  ...currentValues,
                  [name]: value,
                }))
              }}
              menuItemStyle={styles.menuItem}
              menuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    color: '#333333',
                    boxShadow: '0px 0px 8px #00000026',
                  },
                },
              }}
              additionalStyling={{
                '& legend': { display: 'none' },
                '& fieldset': {
                  borderRadius: '8px',
                  border: '1px solid #EAEAEA !important',
                  paddingBottom: '6px',
                },
              }}
            />
          </div>
        </div>
        {availableReport()}
      </CModalBody>
    </CModal>
  )
}

MultiReportComponent.defaultProps = {
  viewAccount: {},
  contactList: [],
  openMultiReport: false,
  setOpenMultiReport: noop,
  setAlertComponent: noop,
  setLoading: noop,
}

MultiReportComponent.propTypes = {
  viewAccount: PropTypes.object,
  contactList: PropTypes.array,
  openMultiReport: PropTypes.bool,
  setOpenMultiReport: PropTypes.func,
  setAlertComponent: PropTypes.func,
  setLoading: PropTypes.func,
}

export default MultiReportComponent
