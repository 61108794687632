import _, { noop } from 'lodash'
import { CCol, CRow } from '@coreui/react'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountRow from 'src/components/AccountRow/AccountRow'
import InvoiceList from 'src/components/InvoiceList/InvoiceList'
import { ReactComponent as InfoIcon } from '../../assets/invoice_icons/info.svg'

import InvoiceBudgetComponent from 'src/components/InvoicePageComponents/InvoiceBudgetComponent/InvoiceBudgetComponent'
import AlertComponent from 'src/components/common/AlertComponent/AlertComponent'
import styles from './invoices.module.scss'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DEFAULT_ERROR_HANDLER,
  getHttpHeaders,
  handleErrorResponse,
  newApiUrl,
} from 'src/utils/apiUtils'
import { getInvoiceReportApiCallInfo } from 'src/Controllers/Invoices/invoicesApi'
import { Backdrop, CircularProgress } from '@mui/material'

/**
 * Account is considered pre bill if billing type is 1 Month"
 * If billing type is 'Only after delivery' then account is considered post bill
 */
const isPostBill = (billingType) => billingType === 'Only after delivery'

export const downloadInvoice =
  (setAlertComponent, setLoading, fruitful_account_id) =>
  async (
    fruitful_invoice_id,
    invoiceDate,
    isPreBill = false,
    isAdjustment = false,
    isMultiAccount = false,
    isAdminSnapshot = false,
    dates = { start: '', end: '' },
  ) => {
    try {
      setLoading(true)
      const invoiceNo = fruitful_invoice_id?.split('-')[1]
      const response = await getInvoiceReportApiCallInfo({
        invoiceNo,
        fruitful_account_id,
        isPreBill,
        isAdjustment,
        isMultiAccount,
        isAdminSnapshot,
        dates,
      })

      if (!response.ok) {
        throw new Error('Error generating PDF')
      }

      const buildName = () => {
        if (_.isEmpty(invoiceNo) && !_.isEmpty(dates)) {
          return `Multi-Account ${
            isAdminSnapshot ? 'Snapshot' : `Invoice Summary ${dates.start.format('MMMM YYYY')}`
          }`
        }

        if (isAdjustment) {
          return `Prior Month Adjustment Breakdown ${invoiceDate}`
        }

        return `Invoice Breakdown ${invoiceDate}`
      }

      const name = buildName()
      // Convert the response to a Blob (binary)
      const blob = await response.blob()

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob)

      // Create an invisible link element to trigger download
      const a = document.createElement('a')
      a.href = url
      a.download = `${name}.pdf` // The name of the downloaded file
      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)

      setAlertComponent({
        open: true,
        severity: 'success',
        message: 'Report downloaded successfully',
      })
    } catch (e) {
      setLoading(false)
      DEFAULT_ERROR_HANDLER(setAlertComponent)(e)
    } finally {
      setLoading(false)
    }
  }

const Invoices = ({ account, Logout }) => {
  const {
    accountname,
    fruitful_account_id,
    status,
    totalTrees,
    date_joined,
    createdtime,
    fo_billing_period,
    budget,
  } = account

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)
  const [alertComponent, setAlertComponent] = useState({
    open: false,
    severity: 'warning',
    message: '',
  })

  const invoices = useQuery({
    enabled: !!fruitful_account_id,
    queryKey: ['invoices', { account: fruitful_account_id }],
    retry: false,
    retryOnMount: true,
    queryFn: async ({ queryKey }) => {
      const [, { account }] = queryKey
      const [region, account_id] = account.split('-')
      const init = getHttpHeaders('GET')
      const response = await fetch(
        `${newApiUrl}/api/invoices?accountId=${account_id}&region=${region}`,
        init,
      )

      if (response.ok) {
        return response.json()
      } else {
        handleErrorResponse(response, queryClient, invoices).catch(
          DEFAULT_ERROR_HANDLER(setAlertComponent),
        )

        return undefined
      }
    },
    select: (data) => {
      const invoices = [...data.Content.Result.items]
      invoices.reverse()
      return invoices
    },
  })

  const openInvoiceDetails = (invoiceId) => {
    navigate(`/invoices/${invoiceId}`)
  }

  const onClickDownload = downloadInvoice(setAlertComponent, setLoading, fruitful_account_id)

  return (
    <>
      <AccountRow
        accountName={accountname}
        accountNumber={fruitful_account_id}
        accountStatus={status}
        accountCreated={moment(date_joined || createdtime)}
        clientSince={moment(date_joined || createdtime, 'YYYY-MM-DD')}
        totalTreeContributions={totalTrees}
      />
      <div className={styles.pageTitles}>Invoices</div>

      <CRow className={styles.subTitleTextRow}>
        <CCol>
          <div className={styles.pageSubtitles}>Invoices List</div>
          <div className={styles.pageSubText}>
            Invoices are emailed in PDF format directly to the Invoicing Contact that you have
            designated. The table below provides a summary of each of the invoices sent to you.
          </div>
        </CCol>
      </CRow>
      <InvoiceList
        invoiceList={invoices.data}
        openInvoiceDetails={openInvoiceDetails}
        isAccountPostBill={isPostBill(fo_billing_period)}
        downloadInvoice={onClickDownload}
      />
      <div className={styles.infoFont}>
        To change your invoice details, select the <InfoIcon className={styles.infoIcon} /> icon in
        the left menu.
      </div>
      <InvoiceBudgetComponent budget={budget} />
      <AlertComponent
        alertComponent={alertComponent}
        alignment={{ vertical: 'top', horizontal: 'center' }}
        setAlertComponent={setAlertComponent}
      />
      <Backdrop
        sx={{ color: '#008848', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={invoices.isFetching || loading}
      >
        <CircularProgress color="success" />
      </Backdrop>
    </>
  )
}

Invoices.defaultProps = {
  account: {},
  Logout: noop,
}

Invoices.propTypes = {
  account: PropTypes.object,
  Logout: PropTypes.func,
}

export default Invoices
